@import "../../main.module.scss";

.Link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    width: 100%;
    height: 100%;
    color: $color-accent;

    &:hover {
        color: $color-default;
    }

    &Icon {
        height: $link-icon-size;
        width: $link-icon-size;
    }

    &Text {
        text-align: center;
        align-content: center;
        font-size: $link-text-size;
    }

}
@import "../../main.module.scss";

.Avatar {
    height: $avatar-size;
    width: $avatar-size;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    .Image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: $avatar-image-size;
        height: $avatar-image-size;

        img {
            width: $avatar-image-size;
            height: $avatar-image-size;
        }
    }

    .Text {
        color: $color-default;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;

        .Header {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            width: 100%;

            .FullName {
                font-size: $avatar-full-name-size;
                font-style: unset;
                font-weight: bold;
            }

            .NickName {
                font-size: $avatar-nickname-size;
            }
        }


        .Footer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .Subject {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: $avatar-subject-size;
                width: 100%;
                font-style: unset;
            }



        }
    }
}
.Live {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    @media screen and (orientation:portrait) {
        padding: 0% 0%;
    }

    @media screen and (orientation:landscape) {
        padding: 0% 0%;
    }

    &Content {
        display: flex;
        justify-content: center;

        @media screen and (orientation:portrait) {
            //flex-direction: column;
        }

        @media screen and (orientation:landscape) {
            //flex-direction: row;
        }

        @media screen and (orientation:portrait) {
            height: 100%;
            width: 100%;
        }

        @media screen and (orientation:landscape) {
            height: 100%;
            width: 100%;
        }

        gap: 5%;
    }
}
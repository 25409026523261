$circle-center-size: 15vmax;

$circle-size: (
    $circle-center-size * 1.75
);

$circle-cluster-node-size: (
    $circle-center-size * 0.5
);

$avatar-size: (
    $circle-center-size
);

$avatar-image-size: (
    $avatar-size * 0.5
);

$avatar-full-name-size: (
    $avatar-image-size * 0.25
);

$avatar-nickname-size: (
    $avatar-full-name-size * 0.75
);

$avatar-subject-size: (
    $avatar-nickname-size * 0.75
);

$link-icon-size: (
    $avatar-nickname-size
);

$link-text-size: (
    $avatar-subject-size
);
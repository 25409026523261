@font-face {
    font-family: "Munro";
    src: local("☺"),
        url("../public/munro.ttf") format("truetype");
}

@font-face {
    font-family: "Consolas";
    src: local("☺"),
        url("../public/Consolas.ttf") format("truetype");
}
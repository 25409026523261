@import "../../main.module.scss";

.ComingSoon {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 16px;
    height: 100%;
    color: $color-accent;
    justify-content: center;
    align-items: center;
}
@import "../../main.module.scss";

.LinkCircle {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    color: $color-accent;
    justify-content: center;
    align-items: center;
    position: relative;

    &Center {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -($circle-center-size / 2);
    }

    &Cluster {
        &Node {
            display: flex;
            justify-content: center;
            align-items: center;

            $maximum: 10;
            //display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: $circle-cluster-node-size;
            height: $circle-cluster-node-size;
            border-radius: 50%;
            background-color: $color-gray-2;
            color: $color-accent;

            &:hover {
                background-color: $color-accent;
                color: $color-default;
            }

            margin: -($circle-cluster-node-size / 2);

            @for $item-count from 1 through $maximum {
                $angle: (
                    360 / $item-count
                );
            $rot: 180;

            &:first-child:nth-last-child(#{$item-count}),
            &:first-child:nth-last-child(#{$item-count})~div {
                @for $i from 1 through $item-count {
                    &:nth-of-type(#{$i}) {
                        transform:
                            rotate($rot * 1deg) translate($circle-size / 2) rotate($rot * -1deg);
                    }

                    $rot: $rot + $angle;
                }
            }
        }
    }
}
}
@charset "UTF-8";
@font-face {
  font-family: "Munro";
  src: local("☺"), url("../public/munro.ttf") format("truetype"); }

@font-face {
  font-family: "Consolas";
  src: local("☺"), url("../public/Consolas.ttf") format("truetype"); }

html,
body,
.root {
  margin: 0;
  height: 100%;
  font-family: 'Consolas';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #444;
  color: #FFF; }
